import React from "react";

const building = "assets/symbols/building.2.crop.circle.svg";

const ContactSection = () => {
  return (
    <>
      <div
        style={{
          display: "grid",
          gap: "20px",
          gridTemplateColumns: "min-content 1fr",
          filter: "drop-shadow(0 0 8px rgba(0, 0, 0, 0.544))",
        }}
      >
        <div>
          <img src={building} className="socialLogo"></img>
        </div>
        <div style={{ textAlign: "left" }}>
          <div style={{ fontWeight: "bold" }}>Rowcave Limited</div>
          <div>Savoy House, Savoy Circus</div>
          <div>London</div>
          <div>W3 7DA</div>
          <div>
            <a href="mailto:info@rowcave.com">info@rowcave.com</a>
          </div>
          <div>
            <a
              href="http://www.rowcave.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.rowcave.com
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactSection;
